import React from 'react';
import Slider from "../components/slider/Slider";
import Intro from '../components/intro/Intro';
import Features from '../components/features/Features';
import Help from '../components/help/Help';

const Home = () => {
    return (
        <>
            <Slider />
            <Intro />
            <Features />
            <Help />
        </>
    );
}

export default Home;
