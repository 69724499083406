export const products = [
    {
        id: 1,
        name: 'Hair Dye Brush',
        url: '/product/1',
        coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
        description: 'This is the description for product 1.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 1.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 2,
        name: 'Lice Comb',
        url: '/product/2',
        coverImage: '/images/products/coverImage2.jpg' || '/images/default.jpg',
        description: 'This is the description for product 2.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 2.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 3,
        name: 'Shower Detangling',
        url: '/product/3',
        coverImage: '/images/products/coverImage3.jpg' || '/images/default.jpg',
        description: 'This is the description for product 3.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 3.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 4,
        name: 'Needle Tail Comb',
        url: '/product/4',
        coverImage: '/images/products/coverImage4.jpg' || '/images/default.jpg',
        description: 'This is the description for product 4.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 4.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 5,
        name: 'Pintail Comb',
        url: '/product/5',
        coverImage: '/images/products/coverImage5.jpg' || '/images/default.jpg',
        description: 'This is the description for product 5.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 5.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 6,
        name: 'Nylon Hair Dye Brush Set',
        url: '/product/6',
        coverImage: '/images/products/coverImage6.jpg' || '/images/default.jpg',
        description: 'This is the description for product 6.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 6.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 7,
        name: 'Detangling Hair brush',
        url: '/product/7',
        coverImage: '/images/products/coverImage7.jpg' || '/images/default.jpg',
        description: 'This is the description for product 7.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 7.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 8,
        name: 'Detangling Massage Brush',
        url: '/product/8',
        coverImage: '/images/products/coverImage8.jpg' || '/images/default.jpg',
        description: 'This is the description for product 8.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 8.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 9,
        name: 'Hair Dye Brush',
        url: '/product/9',
        coverImage: '/images/products/coverImage9.jpg' || '/images/default.jpg',
        description: 'This is the description for product 9.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 9.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 10,
        name: 'Tools Set',
        url: '/product/10',
        coverImage:'/images/products/coverImage10.jpg' || '/images/default.jpg',
        description: 'This is the description for product 10.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 10.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 11,
        name: 'Compact Hair brush',
        url: '/product/11',
        coverImage: '/images/products/coverImage11.jpg' || '/images/default.jpg',
        description: 'This is the description for product 11.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 11.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 12,
        name: 'Shampoo Comb',
        url: '/product/12',
        coverImage: '/images/products/coverImage12.jpg' || '/images/default.jpg',
        description: 'This is the description for product 12.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 12.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 13,
        name: 'Air Massage Comb',
        url: '/product/13',
        coverImage: '/images/products/coverImage13.jpg' || '/images/default.jpg',
        description: 'This is the description for product 13.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 13.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 14,
        name: 'Detangle',
        url: '/product/14',
        coverImage: '/images/products/coverImage14.jpg' || '/images/default.jpg',
        description: 'This is the description for product 14.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 14.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 15,
        name: 'Detangle Hard Pin Brush',
        url: '/product/15',
        coverImage:'/images/products/coverImage15.jpg' || '/images/default.jpg',
        description: 'This is the description for product 15.',
        images: '/images/products/coverImage15.jpg',
        shortDescription: 'Short description of product 15.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 16,
        name: 'Detangler Brush',
        url: '/product/16',
        coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
        description: 'This is the description for product 16.',
        images: '/images/products/coverImage1.jpg',
        shortDescription: 'Short description of product 16.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 17,
        name: 'Hair Clip',
        url: '/product/17',
        coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
        description: 'This is the description for product 17.',
        images: [
            '/images/products/coverImage1.jpg',
            '/images/products/coverImage2.jpg',
            '/images/products/coverImage3.jpg',
        ],
        shortDescription: 'Short description of product 17.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
    {
        id: 18,
        name: 'Pro Detangler',
        url: '/product/18',
        coverImage: '/images/products/coverImage18.jpg' || '/images/default.jpg',
        description: 'This is the description for product 18.',
        images: [
            '/images/products/coverImage18.jpg',
            '/images/products/coverImage18.jpg',
            '/images/products/coverImage18.jpg',
        ],
        shortDescription: 'Short description of product 18.',
        longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
    },
];