import React from "react";
import './intro.css';
import { introData } from "./data";

const Intro = () => {

    const data = introData[0];

        return (
            <>
                <section id="intro">
                    <div className="intro max-w-[1240px] mx-auto grid lg:grid-cols-2 sm:grid-cols-1 gap-[60px] content-center justify-center items-center px-[20px] py-[80px]">
                        <img src={data.image} alt="" className="w-full" />
                            <div className="flex-fill">
                                <h3 className="roboto-black lg:text-3xl md:text-2xl sm:text-xl">{data.title}</h3>
                                <p className="text-[16px]">{data.description}</p>
                            </div>
                    </div>
                </section>
            </>
        );
    }

export default Intro;