import React from "react";
import { helpData } from './data';


const Help = () => {
    return (
        <section id="help" className="intro max-w-[1240px] mx-auto grid lg:grid-cols-1 gap-[30px] content-center justify-center items-center px-[20px] py-[80px] sm:px-[20px] text-center">
            {helpData.map((data) => (
                <div key={data.id} className="">
                    <h3 className="roboto-black text-3xl mb-[30px]">{ data.helpTitle }</h3>
                    <a href={`mailto:${data.helpEmail}`}>
                        <span>{ data.helpEmail }</span>
                    </a>
                </div>
            ))}
        </section>
    )
};

export default Help;