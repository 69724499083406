import React from 'react';
import './cover.css'; // Import CSS for styling

const Cover = ({ backgroundImage, title, description }) => {
    return (
        <div className="layout">
            <div
                className="cover"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className="cover-content">
                    <h1>{title}</h1>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default Cover;
