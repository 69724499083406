// import React, { useState, useEffect } from 'react';
// import './loader.css'; // Include CSS for styling
//
// const Loader = ({ onLoaded }) => {
//     const [progress, setProgress] = useState(0);
//
//     useEffect(() => {
//         // Increment progress every 50ms
//         const interval = setInterval(() => {
//             setProgress(prev => {
//                 if (prev >= 100) {
//                     clearInterval(interval);
//                     onLoaded(); // Notify parent component that loading is complete
//                     return 100;
//                 }
//                 return prev + 1;
//             });
//         }, 50);
//
//         return () => clearInterval(interval);
//     }, [onLoaded]);
//
//     return (
//         <div className="loader">
//             <div className="loader-bar" style={{ width: `${progress}%` }}></div>
//             <div className="loader-text">{progress}%</div>
//         </div>
//     );
// };
//
// export default Loader;

import React from 'react';
import './loader.css';

const Loader = () => {
    return (
        <div className="loader">
            <div className="spinner"></div>
            <p>Loading, please wait...</p>
        </div>
    );
};

export default Loader;
